import {OnEventFn} from '@wix/yoshi-flow-editor'

import {EDITOR_OPEN_TABLE_RESERVATION_BM_EVENT_ID, openReservationsBmOverEditor} from './panels'

export const onEvent: OnEventFn = (event, editorSDK) => {
  switch (event.eventPayload.id) {
    case EDITOR_OPEN_TABLE_RESERVATION_BM_EVENT_ID:
      openReservationsBmOverEditor(editorSDK)
      break
    default:
      break
  }
}
