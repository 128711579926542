import {EditorScriptFlowAPI, GetAppManifestFn, FlowEditorSDK} from '@wix/yoshi-flow-editor'

import {
  APP_PAGE_TPA_ID,
  AppPage,
  RESERVATION_ADD_ON_ID,
  MAX_APP_PAGE_DESKTOP_WIDTH,
  MAX_WIDGET_DESKTOP_WIDTH,
  MIN_APP_PAGE_DESKTOP_WIDTH,
  MIN_MOBILE_WIDTH,
  MIN_WIDGET_DESKTOP_WIDTH,
  APP_PAGE_TO_TITLE_KEY,
  HELP_ARTICLE_IDS,
} from '../utils/constants'

import {EDITOR_OPEN_TABLE_RESERVATION_BM_EVENT_ID} from './panels'

export const getAppManifest: GetAppManifestFn = (
  {appManifestBuilder},
  editorSDK,
  contextParams,
  flowAPI,
) => {
  const settingsTabs = getSettingsTabs(flowAPI)

  const manifest = appManifestBuilder
    .configurePages((pagesBuilder) => {
      // page actions
      pagesBuilder.addAction({
        type: 'page_rename',
      })

      pagesBuilder.set({
        icon: 'restaurantsPageType',
      })

      // page settings
      pagesBuilder.addSettingsTab(settingsTabs.pageInfo)
      pagesBuilder.addSettingsTab(settingsTabs.permissions)
      pagesBuilder.addSettingsTab(settingsTabs.layout)
      pagesBuilder.addSettingsTab(settingsTabs.seo)
    })
    .configurePagesTab((pagesTabBuilder) => {
      // some issue in types
      // @ts-expect-error
      pagesTabBuilder.addAction({
        title: flowAPI.translations.t('editor-reservations.pages-menu-item.remove'),
        icon: 'deleteRadio',
        type: 'REMOVE',
        onClick: async (event) => {
          await editorSDK.application.uninstall('', {
            openConfirmation: true,
          })
        },
      })
    })
    .withJsonManifest({
      controllersStageData: {
        [APP_PAGE_TPA_ID[AppPage.Reservations]]: {
          default: {
            displayName: flowAPI.translations.t(APP_PAGE_TO_TITLE_KEY[AppPage.Reservations]),
            layoutLimits: pageLayoutLimits,
            gfpp: getGfppConfig(flowAPI, editorSDK, HELP_ARTICLE_IDS.reservationsGfpp),
          },
        },
        [APP_PAGE_TPA_ID[AppPage.ReservationDetails]]: {
          default: {
            displayName: flowAPI.translations.t(APP_PAGE_TO_TITLE_KEY[AppPage.ReservationDetails]),
            layoutLimits: pageLayoutLimits,
            gfpp: getGfppConfig(flowAPI, editorSDK, HELP_ARTICLE_IDS.reservationDetailsGfpp),
          },
        },
        [APP_PAGE_TPA_ID[AppPage.ReservationConfirmation]]: {
          default: {
            displayName: flowAPI.translations.t(
              APP_PAGE_TO_TITLE_KEY[AppPage.ReservationConfirmation],
            ),
            layoutLimits: pageLayoutLimits,
            gfpp: getGfppConfig(flowAPI, editorSDK, HELP_ARTICLE_IDS.reservationConfirmationGfpp),
          },
        },
        [RESERVATION_ADD_ON_ID]: {
          default: {
            displayName: flowAPI.translations.t('editor-reservations.pages.reservation-addon.name'),
            layoutLimits: widgetLayoutLimits,
            gfpp: getGfppConfig(flowAPI, editorSDK, HELP_ARTICLE_IDS.reservationAddonGfpp, true),
          },
        },
      },
    })
    .build()

  manifest.pages!.pageDescriptors![AppPage.Reservations] = {
    orderIndex: 3,
  }

  manifest.pages!.pageDescriptors![AppPage.ReservationDetails] = {
    orderIndex: 2,
  }

  manifest.pages!.pageDescriptors![AppPage.ReservationConfirmation] = {
    orderIndex: 1,
  }

  manifest.pages!.pageSettings![AppPage.ReservationDetails] = [
    settingsTabs.pageInfo,
    settingsTabs.permissions,
    settingsTabs.layout,
  ]

  manifest.pages!.pageSettings![AppPage.ReservationConfirmation] = [
    settingsTabs.pageInfo,
    settingsTabs.permissions,
    settingsTabs.layout,
  ]

  return manifest
}

const getLayoutLimits = (minWidth, maxWidth) => ({
  desktop: {
    minWidth,
    maxWidth,
  },
  mobile: {
    minWidth: MIN_MOBILE_WIDTH,
  },
})

const pageLayoutLimits = getLayoutLimits(MIN_APP_PAGE_DESKTOP_WIDTH, MAX_APP_PAGE_DESKTOP_WIDTH)
const widgetLayoutLimits = getLayoutLimits(MIN_WIDGET_DESKTOP_WIDTH, MAX_WIDGET_DESKTOP_WIDTH)

const getGfppConfig = (
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
  helpId?: string,
  showStretchButton?: boolean,
) => ({
  desktop: {
    mainAction2: {
      label: flowAPI.translations.t('editor-reservations.manage.manage-reservations-cta'),
      actionId: EDITOR_OPEN_TABLE_RESERVATION_BM_EVENT_ID,
    },
    iconButtons: {
      stretch: showStretchButton ? ('DEFAULT' as const) : ('HIDE' as const),
    },
    helpId,
  },
  mobile: {
    iconButtons: {},
    helpId,
  },
})

const getSettingsTabs = (flowAPI: EditorScriptFlowAPI) => {
  const pageInfo = {
    title: flowAPI.translations.t('editor-reservations.pages-menu-item.page-info'),
    type: 'page_info' as const,
  }

  const layout = {
    title: flowAPI.translations.t('editor-reservations.pages-menu-item.layout'),
    type: 'layout' as const,
  }

  const seo = {
    title: flowAPI.translations.t('editor-reservations.pages-menu-item.seo'),
    type: 'seo' as const,
  }

  const permissions = {
    title: flowAPI.translations.t('editor-reservations.pages-menu-item.permissions'),
    type: 'permissions' as const,
  }

  return {
    pageInfo,
    layout,
    seo,
    permissions,
  }
}
